import React, { createContext, useState, useEffect } from 'react';
import axiosClient from '../BaseURL/baseUrl';

const AuthContext = createContext({
  isLogin: false,
  loginData: {},
  isSignup: false,
  signupData: {},
  errorData: {},
  onLogout: () => {},
  onLogin: (mobileNumber, password) => {},
  onSignup: (name, mobileNumber, OTP, password) => {},
});

export const AuthContextProvider = (props) => {
  const [isLogin, setIsLogin] = useState(false);
  const [loginData, setLoginData] = useState(null);
  const [isSignup, setIsSignup] = useState(false);
  const [signupData, setSignupData] = useState(null);
  const [errorData, setErrorData] = useState(null);

  
  useEffect(() => {
    const storedLoggedInUserInfo = localStorage.getItem('isLoggedIn');
    if (storedLoggedInUserInfo === '1') {
      setIsLogin(true);
    }
  }, []);

  const logoutHandler = () => {
    localStorage.clear();
    setErrorData('');
    setIsLogin(false);
    setLoginData(null);
  };

  const loginHandler = async (mobileNumber, password) => {
    setErrorData('');
    try {
        const dataToSend = {
            mobile:mobileNumber, 
            password:password 
        }
        const response = await axiosClient.post('/login', dataToSend);
        const res = response.data;
        localStorage.setItem('isLoggedIn', '1');
        localStorage.setItem('access_config', '{}');
        localStorage.setItem('token', res.data.api_token);
        localStorage.setItem('isemployee', res.data.isemployee);
        localStorage.setItem('customer_id', res.data.id);
        setLoginData((prev) => ({ ...prev, ...res.data }));
        setIsLogin(true);
    } 
    catch (err) {
      console.error(err);
      setErrorData(err);
    }
  };

  const signupHandler = async (company_id, isemployee, mobile, name, password, password_confirmation, token) => {
    setErrorData('');
    try {    
      const dataToSend = {
        company_id:company_id, 
        isemployee:isemployee, 
        mobile:mobile, 
        name:name, 
        password:password, 
        password_confirmation:password_confirmation, 
        token:token 
      }
       const response = await axiosClient.post('/register', dataToSend);
       const res = response.data;
       setSignupData((prev) => ({ ...prev, ...res.data }));
       setIsSignup(true);

      //also login the user as we have the token as well returned after signup
      localStorage.setItem('isLoggedIn', '1');
      localStorage.setItem('access_config', '{}');
      localStorage.setItem('token', res.data.api_token);
      localStorage.setItem('isemployee', res.data.isemployee);
      localStorage.setItem('customer_id', res.data.id);
      setLoginData((prev) => ({ ...prev, ...res.data }));
      setIsLogin(true);
    } 
    catch (err) {
      console.error(err);
      setErrorData(err);
    }

    
  };

  return (
    <AuthContext.Provider
      value={{
        isLogin: isLogin,
        onLogin: loginHandler,
        onSignup: signupHandler,
        onLogout: logoutHandler,
        loginData: loginData,
        signupData: signupData,
        errorData: errorData,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
