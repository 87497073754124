import axiosClient from '../BaseURL/baseUrl';


export const LOGIN = async (mobileNumber, password) => {
    try {
        const dataToSend = {
            mobile:mobileNumber, 
            password:password 
        }

        const response = await axiosClient.post('/login', dataToSend);
        console.log(response.data);
        return response.data;
    } 
    catch (error) {
        throw new Error('Failed to login. Please check your credentials.');        
    }
   
};
  
export const SEND_PASSWORD_RESET_OTP = async (mobile) => {
    try {
    // Perform logic for handling forgot password
    // Example: Send OTP via WhatsApp
    const response = await axiosClient.post('/user/resetPassword', { mobile });

    return response.data;
    } 
    catch (error) {
    throw new Error('Failed to reset the password. Please try again.');
    }
};

export const GET_SIGNUP_OTP = async (mobile) => {
    try {
        const response = await axiosClient.post('/requestOTP', { mobile });

        return response.data;
    } 
    catch (error) {
    throw new Error('Failed to send OTP. Please try again.');
    }
};


export const SIGNUP = async (company_id, isemployee, mobile, name, password, password_confirmation, token) => {
    try {
        const response = await axiosClient.post('/register', { company_id, isemployee, mobile, name, password, password_confirmation, token });

        return response.data;
    } 
    catch (error) {
    throw new Error('Failed to register. Please try again.');
    }
};

export const CHANGE_PASSWORD = async (payload) => {
    try {
        const response = await axiosClient.post(`/user/password`, payload );
        console.log(response);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to change Password. Please try again.');
    }
};

export const GET_CUSTOMER_DATA = async (id) => {
    try {
        const response = await axiosClient.get(`/customer/${id}`);
        return response.data[0];
    } 
    catch (error) {
        throw new Error('Unable to get customer data. Please try again.');
    }
};

export const GET_DASHBOARD_DATA = async (duration) => {
    try {
        const response = await axiosClient.get(`/mdashboard/overall/${duration}`);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to get customer data. Please try again.');
    }
};

export const GET_ORDERS_DATA = async (status, page) => {
    try {
        const response = await axiosClient.get(`/orders/${status}?page=${page}`);
        console.log(response);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to get order data. Please try again.');
    }
};

export const GET_ALL_ORDERS_DATA = async (page) => {
    try {
        const response = await axiosClient.get(`/orders?page=${page}`);
        console.log(response);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to get order data. Please try again.');
    }
};

export const GET_FILTERED_ORDERS_DATA = async (payload) => {
    
    try {
        const response = await axiosClient.get(`/orders?awbs=${payload?.awbs}&vendorId=${payload?.vendorId}&awbs=${payload?.awbs}&customerId=${payload?.customerId}&statusCode=${payload?.statusCode}&orderTypeCode=${payload?.orderTypeCode}&startDate=${payload?.startDate}&endDate=${payload?.endDate}&pin=${payload?.pin}`);
        console.log(response);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to get order data. Please try again.');
    }
};


export const CREATE_MANUAL_ORDER = async (data) => {
    try {
        const response = await axiosClient.post(`/orders`, data );
        console.log(response);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to get create order. Please try again.');
    }
};

export const CANCEL_ORDER = async (id) => {
    const payload = {update_type: "cancel"}
    try {
        const response = await axiosClient.put(`/orders/${id}`, payload );
        console.log(response);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to get create order. Please try again.');
    }
};

export const CREATE_BULK_ORDER = async (data) => {
    try {
        const response = await axiosClient.post(`/bulk_action`, data );
        console.log(response);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to create order. Please try again.');
    }
};

export const GET_PICKUP_ADDRESS = async (customerId) => {
    try {
        const response = await axiosClient.get(`/pickupAddress/${customerId}`);
        console.log(response);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to get customer data. Please try again.');
    }
};

export const DELETE_PICKUP_ADDRESS = async (id) => {
    try {
        const response = await axiosClient.delete(`/pickupAddress/${id}`);
        console.log(response);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to delete. Please try again.');
    }
};

export const CREATE_NEW_PICKUP_ADDRESS = async (payload) => {
    try {
        const response = await axiosClient.post(`/pickupAddress`, payload );
        console.log(response);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to add new pickup address. Please try again.');
    }
};

export const GET_COURIER_LIST_WITH_RATES = async (orderId, pickupLocationId) => {
    try {
        const response = await axiosClient.get(`/couriers?awb=${orderId}&pickupAddressId=${pickupLocationId}`);
        console.log(response);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to get customer data. Please try again.');
    }
};


export const SHIP_MANUAL_ORDER = async (orderId, payload) => {
    try {
        const response = await axiosClient.put(`/orders/${orderId}`, payload );
        console.log(response);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to add new pickup address. Please try again.');
    }
};

export const USER_PROFILE_UPDATE_WITH_FILE = async (model, customerID) => {
    let auth = `Bearer ${localStorage.getItem('token')}`;
    let headers = new Headers({
      'enctype': 'multipart/form-data',
      Authorization: auth
    });
    const options = { headers: headers };
    const formData = new FormData();

    Object.keys(model).forEach(key => {
      if(model[key] instanceof File){
        formData.append(key, model[key], model[key].name);
      }else{
        formData.append(key, model[key]);
      }
    });    

    try {
        const response = await axiosClient.post(`/profileupdate/${customerID}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': auth
            }
            });
        console.log(response);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to update profile data. Please try again.');
    }
}

export const SAVE_LABEL_PRINT_OPTIOPNS = async (customerID, payload) => {
    console.log(payload);
    try {
        const response = await axiosClient.put(`/customer/${customerID}`, payload);
        console.log(response);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to add new pickup address. Please try again.');
    }
};


export const GET_PASSBOOK_DATA = async (page, date) => {
    try {
        const response = await axiosClient.get(`/passbook?page=${page}&endDate=${date}`);
        console.log(response);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to get passbook data. Please try again.');
    }
};

export const CREATE_AMAZON_PRINT_LABEL = async (AWB) => {
    try {
        const response = await axiosClient.get(`/printLabel?awb=${AWB}`);
        console.log(response);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to print label. Please try again.');
    }
}

export const UPDATE_COURIER_PREFERENCE = async (id, payload) => {
    try {
        const response = await axiosClient.put(`/customer/${id}`, payload);
        return response.data[0];
    } 
    catch (error) {
        throw new Error('Unable to update. Please try again.');
    }
};


export const SEND_BULK_SHIPMENTS = async (payload) => {
    try {
        const response = await axiosClient.post(`/bulk_action`, payload );
        console.log(response);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to send bulk shipments. Please try again.');
    }
}; 

export const CANCEL_BULK_SHIPMENTS = async (payload) => {
    try {
        const response = await axiosClient.post(`/bulk_action`, payload );
        console.log(response);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to cancel bulk shipments. Please try again.');
    }
}; 

export const DOWNLOAD_FILE = (url) => {
    const auth =  `Bearer ${localStorage.getItem('token')}`;
    var form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", url);
    form.setAttribute("target", "_blank");
    var hiddenField = document.createElement("input");
    hiddenField.setAttribute("type", "hidden");
    hiddenField.setAttribute("name", "Authorization");
    hiddenField.setAttribute("value", auth);
    form.appendChild(hiddenField);

    document.body.appendChild(form);
    form.submit();

    return;
}

export const GET_STORES_BY_CUSTOMER_ID = async (customerID) => {
    try {
        const response = await axiosClient.get(`/integrations?customer_id=${customerID}`);
        console.log(response);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to get Stores data. Please try again.');
    }
}

export const ADD_NEW_STORE = async (payload) => {
    try {
        const response = await axiosClient.post(`/integrations`, payload );
        console.log(response);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to add new store. Please try again.');
    }
}; 

export const EDIT_STORE_DATA = async (id, payload) => {
    try {
        const response = await axiosClient.put(`/integrations/${id}`, payload );
        console.log(response);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to edit store data. Please try again.');
    }
}; 

export const DELETE_STORE = async (id) => {
    try {
        const response = await axiosClient.delete(`/integrations/${id}`);
        console.log(response);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to edit store data. Please try again.');
    }
}; 

export const UPDATE_DATA_BEFORE_SHIPMENT = async (payload) => {
    try {
        const response = await axiosClient.post(`/updatedata`, payload );
        console.log(response);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to update. Please try again.');
    }
}; 

export const UPDATE_DELIVERY_ADDRESS = async (id, payload) => {
    try {
        const response = await axiosClient.put(`/address/${id}`, payload );
        console.log(response);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to update delivery address. Please try again.');
    }
}; 

export const UPDATE_PICKUP_ADDRESS = async (id, payload) => {
    try {
        const response = await axiosClient.put(`/address/${id}`, payload );
        console.log(response);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to update delivery address. Please try again.');
    }
}; 


export const GET_DAILY_MIS_REPORT = async (startDate, endDate, pageNum) => {
    try {
        const response = await axiosClient.get(`/mreports/mis/view/${startDate}/${endDate}?page=${pageNum}`);
        console.log(response.data);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to get MIS Report. Please try again.');
    }
};


export const GET_COD_REPORT = async (startDate, endDate, pageNum) => {
    try {
        const response = await axiosClient.get(`/mreports/cod/view/${startDate}/${endDate}?page=${pageNum}`);
        console.log(response.data);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to get COD Report. Please try again.');
    }
};

export const GET_MENIFEST_REPORT = async (startDate, endDate, pageNum, vendorId) => {
    try {
        const response = await axiosClient.get(`/mreports/manifest/view/${startDate}/${endDate}?page=${pageNum}&vendorId=${vendorId}`);
        console.log(response.data);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to get RVP MIS Report. Please try again.');
    }
};


export const GET_NDR_REPORT = async (startDate, endDate, pageNum) => {
    try {
        const response = await axiosClient.get(`/mreports/ndr/view/${startDate}/${endDate}?page=${pageNum}`);
        console.log(response.data);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to get NDR Report. Please try again.');
    }
};

export const GET_RVP_REPORT = async (startDate, endDate, pageNum) => {
    try {
        const response = await axiosClient.get(`/mreports/rvpmis/view/${startDate}/${endDate}?page=${pageNum}`);
        console.log(response.data);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to get RVP MIS Report. Please try again.');
    }
};

export const GET_VENDOR_LIST = async () => {
    try {
        const response = await axiosClient.get(`/vendors`);
        console.log(response);
        return response;
    } 
    catch (error) {
        throw new Error('Unable to get RVP MIS Report. Please try again.');
    }
};


export const GET_STORE_DATA = async (pageNum, storeName, storeId, custId, orderType, orderNum,startDate, endDate) => {
    try {
        const response = await axiosClient.get(`/integrations/orders?page=${pageNum}&integration=${storeName}&store_id=${storeId}&customer_id=${custId}&type=${orderType}&order_no=${orderNum}&start_date=${startDate}&end_date=${endDate}`);
        console.log(response.data);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to get RVP MIS Report. Please try again.');
    }
};


export const GET_GLOBAL_SEARCH_RESULT = async (pageNum, awb) => {
    try {
        const response = await axiosClient.get(`/orders?page=${pageNum}&awbs=${awb}`);
        console.log(response.data);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to get RVP MIS Report. Please try again.');
    }
};


export const RE_SHIPMENT = async (payload) => {
    try {
        const response = await axiosClient.post(`/bulk_action`, payload );
        console.log(response);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to re-attempt shipping. Please try again.');
    }
}; 


export const RTO_SHIPMENT = async (payload) => {
    try {
        const response = await axiosClient.post(`/bulk_action`, payload );
        console.log(response);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to submit RTO request. Please try again.');
    }
}; 


export const GET_SHIPPING_RATES = async (customer_id, vendor_id, vendor_type) => {
    try {
        const response = await axiosClient.get(`/customerRates?pagination=Y&customer_id=${customer_id}&vendor_id=${vendor_id}&type=${vendor_type}`);
        console.log(response.data);
        return response.data;
    } 
    catch (error) {
        throw new Error('Unable to get RVP MIS Report. Please try again.');
    }
};