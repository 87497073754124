import React, { useContext  } from 'react';
import AuthScreen from './pages/Auth';
import MembersLayout from './layouts/MembersLayout';
import AdminLayout from './layouts/AdminLayout';
import AuthContext from './context/auth-context';


function App() {
  const authContext = useContext(AuthContext);

  if(!Number(localStorage.getItem('isLoggedIn'))){
    return <AuthScreen />;
  }
  else if(Number(localStorage.getItem('isLoggedIn'))){
    if(Number(localStorage.getItem('isemployee')) === 0){
      return <MembersLayout />;
    }
    if(Number(localStorage.getItem('isemployee')) === 1){
      return <AdminLayout />;
    }
  }
}

export default App;
