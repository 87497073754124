import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthContextProvider } from './context/auth-context';
import { SidebarContextProvider } from './context/SidebarContext'
import App from './App';
import './App.css';

const rootElement = document.getElementById('root');

createRoot(rootElement).render(
  <React.StrictMode>
    <AuthContextProvider>
        <BrowserRouter>
          <SidebarContextProvider>
            <App />
          </SidebarContextProvider>
        </BrowserRouter>      
    </AuthContextProvider>
  </React.StrictMode>
);