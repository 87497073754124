import React, { useState, useContext, useEffect  } from 'react';
import Styles from './Header.module.css';
import Swal from 'sweetalert2';
import * as authService from '../../services/authService';
import * as fn from '../reusable/Functions/Functions';
import { useNavigate, Link } from 'react-router-dom';
import AuthContext from '../../context/auth-context';
import SidebarContext from '../../context/SidebarContext';
import Logo from '../../assets/images/logo.png';
import SR_NO_SEARCH_IMAGE from '../../assets/images/SR_NO_SEARCH_IMAGE.gif';
import SR_NO_RESULT_IMAGE from '../../assets/images/SR_NO_RESULT_IMAGE.gif';
import { Input, Button, Avatar, Dropdown, Space, Drawer } from 'antd';

import {
  UserOutlined,
  MenuOutlined,
  WalletOutlined,
  CloseCircleOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PoweroffOutlined,
  SettingOutlined,
  SearchOutlined,
} from '@ant-design/icons';

const Header = (props) => {
  const {customerName} = props;
  const authContext = useContext(AuthContext);
  const sidebarContext = useContext(SidebarContext);
  const navigate = useNavigate();
  const access_config = JSON.parse(localStorage.getItem('access_config'));
  console.log(JSON.parse(localStorage.getItem('CUSTOMER_DATA'))?.balance)
  const currentBalance = JSON.parse(localStorage.getItem('CUSTOMER_DATA'))?.balance !== null
  ? Number(JSON.parse(localStorage.getItem('CUSTOMER_DATA'))?.balance) : 0;

  const [searchText, setSearchText] = useState('');
  const [searchResult, setSearchResult] = useState(null);
  const [dataRows, setDataRows] = useState(null);
  const index = 0;

  

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
      setOpen(true);
  };
  const onClose = () => {
      setOpen(false);
      setSearchText('');
      setSearchResult(null);
  };

  const { Search } = Input;
  
  const onSearch = (value, _e, info) => {
    console.log(value);
    setSearchText(value);
    getSearchResults(value);
  };

  const getSearchResults = async(awb) => {
    try{
      const resp = await authService.GET_GLOBAL_SEARCH_RESULT(0, awb);
      console.log(resp);
      setSearchResult(resp?.data);
      setDataRows(resp?.data);
    }
    catch(err){
      console.log(err);
    }
  }

  const handlemenuCollapse = () => {
    sidebarContext.toggleCollapsed();
  };

  const handleLogOut = () => {
    console.log('Logout');
    Swal.fire({
      title: "Do you want to Log Out?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#ff4d4f",
      confirmButtonText: "Yes"
    }).then((result) => {
      if (result.isConfirmed) {
        authContext.onLogout();
        navigate('/');
        Swal.fire({
          title: "Logged Out",
          text: "You have sucessfully logged out.",
          icon: "success"
        });
      }
    });
    
  }

  const items = access_config?.show_header_avatar_drop_down_menu ? [
    {
      label: <Link to="/my-profile">Profile</Link>,
      key: '0',
      icon: <UserOutlined />,
    },
    {
      label: <Link to="/my-wallet">Wallet</Link>,
      key: '1',
      icon: <WalletOutlined />,
    },
    {
      label: <Link to="/channel-integration">Channel Integration</Link>,
      key: '2',
      icon: <SettingOutlined />,
    },
    {
      type: 'divider',
    },
    {
      label: <span className={Styles.logout_btn_wrapper}>Log Out</span>,
      key: '4',
      icon: <PoweroffOutlined />,
      danger: true,
      onClick: handleLogOut
    },
  ] : [];

  return (
    <header className={Styles.header_wrapper}>
      <div className={Styles.left_wrapper}>
          {access_config?.show_header_collapse_btn && (
            <div className={Styles.toggle_icon_wrapper} onClick={handlemenuCollapse}>
                {!sidebarContext.menuCollapsed? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
            </div>
          )}
          
          <div className={Styles.logo_wrapper}>
              <img className={Styles.logo} src={Logo} alt="Logo" />
          </div>
      </div>
      
      {access_config?.show_header_search_bar_desktop && (
        <div className={Styles.middle_wrapper}>
            <div className={Styles.search_wrapper}>
              <Button 
                type="primary" 
                shape="circle" 
                icon={<SearchOutlined />} 
                onClick={()=>setOpen(true)}
              />
            </div>
        </div>
      )}
      

      <div className={Styles.right_wrapper}>
          {access_config?.show_header_search_btn_mobile && (
            <div className={Styles.mobile_search_wrapper} onClick={showDrawer}>
                <SearchOutlined 
                  twoToneColor="#83007a"
                  style={{
                    fontSize: '30px',
                    verticalAlign: 'middle',
                    marginRight: '10px'
                  }}
                />
            </div>
          )}
          
          {access_config?.show_header_search_btn_mobile &&  (
              <div className={Styles.recharge_btn_wrapper} onClick={()=>''}>
                  <Button type="primary" size={'medium'} icon={<WalletOutlined />} danger>
                    <Link to="/wallet-recharge">
                      {currentBalance <= 0 && (
                        <>
                          Recharge
                        </>
                      )}
                       {currentBalance > 0 && (
                        <>
                          <span className="inr-sign"></span>&nbsp;{currentBalance}
                        </>
                      )}
                      
                    </Link>                
                  </Button>
              </div>
          )}     
          

          <div className={Styles.user_profile_wrapper} onClick={()=>''}>
              <Dropdown
                menu={{
                  items,
                }}
                trigger={['click']}
                placement="bottomRight" 
                arrow
              >
                  <a onClick={(e) => e.preventDefault()}>
                      <Space>
                          <Avatar
                            style={{
                              backgroundColor: '#1A237E',
                              verticalAlign: 'middle',
                            }}
                            size="large"
                            gap={4}
                          >
                            {customerName}
                          </Avatar>
                      </Space>
                  </a>
              </Dropdown>              
          </div>          
      </div>

      <Drawer
        title={<SearchOutlined style={{fontSize: '24px'}} />}
        placement={'top'}
        height={'100vh'}
        closable={true}
        onClose={onClose}
        open={open}
      >
        <div className={Styles.mobile_search_container}>
          <div className={Styles.mobile_search_bar}>
              <h4>Search for AWB/Order ID</h4>
              <Search 
                  placeholder="Example: 12345ABC67890" 
                  onSearch={onSearch}
                  value={searchText}
                  onChange={e=>setSearchText(e.target.value)}
                  enterButton 
                  style={{
                    width: 300,
                }}
              /> 
          </div>
          <div className='fade_rule'></div> 
        </div>  
        <div className={Styles.mobile_search_results}>
          <>   
              {searchResult === null && (
                  <div className={Styles.no_search_screen}>
                      <img src={SR_NO_SEARCH_IMAGE} alt="Search Result Image" />
                      <p>Your search result will appear here</p>
                  </div>
              )}

              {(searchResult !== null && searchResult?.length === 0) && (
                  <div className={Styles.no_search_result_screen}>
                        <img src={SR_NO_RESULT_IMAGE} alt="No Search Result Found" />
                        <p>Ooops! No Search Result Found for </p>
                        <p className={Styles.search_text}>{searchText}</p>
                  </div>
              )}

              {(searchResult !== null && searchResult?.length > 0) && (
                  <div className={Styles.result_screen}>
                       <div  className={Styles.container_wrapper}> 
                          <div>
                              {!!dataRows[index] && (
                                  <div className={Styles.box_wrapper}>
                                      <div className={Styles.box_title}>
                                          Order Status
                                      </div>
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>Booking Date</p>
                                          <p className={Styles.data_value}>{fn.getDisplayDate(dataRows[index]?.booking_date, true)}</p>     
                                      </div>
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>AWB Number</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.awb}</p>     
                                      </div>
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>Status</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.status_lookup?.value}</p>     
                                      </div>
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>Order Type</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.lookup?.value}</p>     
                                      </div>                    
                                  </div>
                              )}
                              

                              <div className={Styles.box_wrapper}>
                                  <div className={Styles.box_title}>
                                      Charge Breakup
                                  </div>
                                  {!!dataRows[index] && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>Declared Value</p>
                                          <p className={Styles.data_value}>
                                              <span className="inr-sign"></span>&nbsp;
                                              {dataRows[index]?.declared_value}
                                          </p>       
                                      </div>
                                  )}
                                  {!!dataRows[index] && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>Collectable Value</p>
                                          <p className={Styles.data_value}>
                                              <span className="inr-sign"></span>&nbsp;
                                              {dataRows[index]?.collectable_value}
                                          </p>       
                                      </div>
                                  )}
                              </div>
                          </div> 
                          
                          
                          <div className={Styles.box_wrapper}>
                              <div className={Styles.box_title}>
                                  Products Details
                              </div>
                              {dataRows[index]?.order_sku.length > 0 && (
                                  <>
                                      {dataRows[index]?.order_sku.map((prod, index) => {
                                          return(
                                              <div key={index} className={Styles.product_wrapper}>
                                                  {!!prod?.sku && (
                                                      <div className={Styles.data_wrapper}>
                                                          <p className={Styles.data_lebel}>SKU</p>
                                                          <p className={Styles.data_value}>{prod?.sku}</p>       
                                                      </div>
                                                  )}

                                                  {!!prod?.description && (
                                                      <div className={Styles.data_wrapper}>
                                                          <p className={Styles.data_lebel}>Description</p>
                                                          <p className={`${Styles.data_value} ${Styles.address}`}>{prod?.description}</p>       
                                                      </div>
                                                  )}

                                                  {!!prod?.quantity && (
                                                      <div className={Styles.data_wrapper}>
                                                          <p className={Styles.data_lebel}>Quantity</p>
                                                          <p className={Styles.data_value}>{prod?.quantity}</p>       
                                                      </div>
                                                  )}
                                                  
                                                  {!!prod?.brand && (
                                                      <div className={Styles.data_wrapper}>
                                                          <p className={Styles.data_lebel}>Brand</p>
                                                          <p className={Styles.data_value}>{prod?.brand}</p>       
                                                      </div>
                                                  )}
                                                  
                                                  {!!prod?.colour && (
                                                      <div className={Styles.data_wrapper}>
                                                          <p className={Styles.data_lebel}>Colour</p>
                                                          <p className={Styles.data_value}>{prod?.colour}</p>       
                                                      </div>
                                                  )}
                                                  
                                                  {!!prod?.return_reason && (
                                                      <div className={Styles.data_wrapper}>
                                                          <p className={Styles.data_lebel}>Return Reason</p>
                                                          <p className={Styles.data_value}>{prod?.return_reason}</p>       
                                                      </div>
                                                  )}
                                              </div>
                                          )
                                      })}
                                  </>
                              )}
                          </div>

                          <div>           
                              <div className={Styles.box_wrapper}>
                                  <div className={Styles.box_title}>
                                      Dimension & Weight
                                  </div>
                                  {!!dataRows[index] && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>Actual Weight</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.weight} Grams</p>       
                                      </div>
                                  )}
                                  {!!dataRows[index] && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>L | B | H</p>
                                          <p className={Styles.data_value}>
                                              {dataRows[index]?.length} CM | {dataRows[index]?.breadth} CM | {dataRows[index]?.height} CM
                                          </p>       
                                      </div>
                                  )}
                                  {!!dataRows[index] && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>Applicable Weight</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.applicable_weight} Kg</p>       
                                      </div>
                                  )}
                              </div>                

                              <div className={Styles.box_wrapper}>
                                  <div className={Styles.box_title}>
                                      Pickup Address
                                  </div>
                                  {!!dataRows[index]?.pickup_address?.receiver && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>Receiver Name</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.pickup_address?.receiver}</p>       
                                      </div>
                                  )}

                                  {!!dataRows[index]?.pickup_address?.nick_name && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>Address Alias / Nick Name</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.pickup_address?.nick_name}</p>     
                                      </div>
                                  )}
                                  
                                  {!!dataRows[index]?.pickup_address?.address && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>Detailed Address</p>
                                          <p className={`${Styles.data_value} ${Styles.address}`}>{dataRows[index]?.pickup_address?.address}</p>     
                                      </div>
                                  )}
                                  
                                  {!!dataRows[index]?.pickup_address?.city && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>City</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.pickup_address?.city}</p>     
                                      </div>
                                  )}

                                  {!!dataRows[index]?.pickup_address?.state && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>State</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.pickup_address?.state}</p>     
                                      </div>
                                  )}
                                  
                                  {!!dataRows[index]?.pickup_address?.pin && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>PIN</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.pickup_address?.pin}</p>     
                                      </div>
                                  )}
                                  
                                  {!!dataRows[index]?.pickup_address?.country && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>Country</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.pickup_address?.country}</p>     
                                      </div>
                                  )}
                                  
                                  {!!dataRows[index]?.pickup_address?.mobile && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>Mobile (Personal)</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.pickup_address?.mobile}</p>     
                                      </div>
                                  )}

                                  {!!dataRows[index]?.pickup_address?.office_mobile && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>Mobile (Office)</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.pickup_address?.office_mobile}</p>     
                                      </div>
                                  )}
                                  
                                  {!!dataRows[index]?.pickup_address?.email && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>E-Mail</p>
                                          <p className={`${Styles.data_value} ${Styles.email}`}>{dataRows[index]?.pickup_address?.email}</p>     
                                      </div>
                                  )}
                              </div>
                          </div> 
                          
                          {dataRows[index].address !== null && (
                              <div className={Styles.box_wrapper}>
                                  <div className={Styles.box_title}>
                                      Delivery Address
                                  </div>
                                  {!!dataRows[index]?.address?.address_type && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>Address Type</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.address?.address_type}</p>       
                                      </div>
                                  )}

                                  {!!dataRows[index]?.address?.receiver && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>Name</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.address?.receiver}</p>     
                                      </div>
                                  )}
                                  
                                  {!!dataRows[index]?.address?.address && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>Address</p>
                                          <p className={`${Styles.data_value} ${Styles.address}`}>{dataRows[index]?.address?.address}</p>     
                                      </div>
                                  )}
                                  
                                  {!!dataRows[index]?.address?.city && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>City</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.address?.city}</p>     
                                      </div>
                                  )}

                                  {!!dataRows[index]?.address?.state && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>State</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.address?.state}</p>     
                                      </div>
                                  )}
                                  
                                  {!!dataRows[index]?.address?.pin && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>PIN</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.address?.pin}</p>     
                                      </div>
                                  )}
                                  
                                  {!!dataRows[index]?.address?.country && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>Country</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.address?.country}</p>     
                                      </div>
                                  )}
                                  
                                  {!!dataRows[index]?.address?.mobile && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>Mobile (Personal)</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.address?.mobile}</p>     
                                      </div>
                                  )}

                                  {!!dataRows[index]?.address?.office_mobile && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>Mobile (Office)</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.address?.office_mobile}</p>     
                                      </div>
                                  )}
                                  
                                  {!!dataRows[index]?.address?.email && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>E-Mail</p>
                                          <p className={`${Styles.data_value} ${Styles.email}`}>{dataRows[index]?.address?.email}</p>     
                                      </div>
                                  )}
                              </div> 
                          )}            

                          {dataRows[index].address === null && (
                              <div className={Styles.box_wrapper}>
                                  <div className={Styles.box_title}>
                                      Pickup Address
                                  </div>
                                  {!!dataRows[index]?.pickup_address?.nick_name && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>Address Nick Name</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.pickup_address?.nick_name}</p>       
                                      </div>
                                  )}

                                  {!!dataRows[index]?.pickup_address?.receiver && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>Receiver Name</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.pickup_address?.receiver}</p>     
                                      </div>
                                  )}
                                  
                                  {!!dataRows[index]?.pickup_address?.address && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>Address</p>
                                          <p className={`${Styles.data_value} ${Styles.address}`}>{dataRows[index]?.pickup_address?.address}</p>     
                                      </div>
                                  )}
                                  
                                  {!!dataRows[index]?.pickup_address?.city && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>City</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.pickup_address?.city}</p>     
                                      </div>
                                  )}

                                  {!!dataRows[index]?.pickup_address?.state && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>State</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.pickup_address?.state}</p>     
                                      </div>
                                  )}
                                  
                                  {!!dataRows[index]?.pickup_address?.pin && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>PIN</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.pickup_address?.pin}</p>     
                                      </div>
                                  )}
                                  
                                  {!!dataRows[index]?.pickup_address?.country && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>Country</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.pickup_address?.country}</p>     
                                      </div>
                                  )}
                                  
                                  {!!dataRows[index]?.pickup_address?.mobile && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>Mobile (Personal)</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.pickup_address?.mobile}</p>     
                                      </div>
                                  )}

                                  {!!dataRows[index]?.pickup_address?.office_mobile && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>Mobile (Office)</p>
                                          <p className={Styles.data_value}>{dataRows[index]?.pickup_address?.office_mobile}</p>     
                                      </div>
                                  )}
                                  
                                  {!!dataRows[index]?.pickup_address?.email && (
                                      <div className={Styles.data_wrapper}>
                                          <p className={Styles.data_lebel}>E-Mail</p>
                                          <p className={`${Styles.data_value} ${Styles.email}`}>{dataRows[index]?.pickup_address?.email}</p>     
                                      </div>
                                  )}
                              </div> 
                          )}
                       </div>                         
                  </div>
              )}
          </>  
           

           

           
        </div>              
      </Drawer>

      
    </header>
  );
};

export default Header;
